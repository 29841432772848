<template>
    <v-app style="background: transparent" :class="{}">

        <!-- begin:: Content Head -->
        <KTSubheader
                v-if="nav.subheaderDisplay"
                v-bind:breadcrumbs="nav.breadcrumbs"
                v-bind:title="nav.pageTitle"
        />
        <!-- end:: Content Head -->

        <div class="d-flex flex-column-fluid">
            <!--begin::Container-->
            <div class="container">

                <!--begin::Card-->
                <div class="card card-custom">
                    <div class="card-header flex-wrap border-0 pt-6 pb-0">
                        <div class="card-title">
                            <h3 class="card-label">库存流水
                                <span class="d-block text-muted pt-2 font-size-sm"></span>
                            </h3>
                        </div>
                        <div class="card-toolbar">

                        </div>
                    </div>
                    <div class="card-body">
                        <!--begin: Search Form-->

                        <!--begin::Search Form-->
                        <div class="mb-7">
                            <div class="row align-items-center">
                                <div class="col-lg-12 col-xl-12">
                                    <div class="row align-items-center">
                                        <div class="col-md-3 my-2 my-md-0">
                                            <div class="d-flex align-items-center">
                                                <label class="mr-3 mb-0 d-none d-md-block" style="width: 80px;">开始时间:</label>
                                                <KTDatePicker
                                                        v-model="search_form.begin_time"
                                                ></KTDatePicker>
                                            </div>
                                        </div>
                                        <div class="col-md-3 my-2 my-md-0">
                                            <div class="d-flex align-items-center">
                                                <label class="mr-3 mb-0 d-none d-md-block" style="width: 80px;">结束时间:</label>
                                                <KTDatePicker
                                                        v-model="search_form.end_time"
                                                ></KTDatePicker>
                                            </div>
                                        </div>

                                        <div class="col-md-3 my-2 my-md-0">
                                            <div class="d-flex align-items-center">
                                                <label class="mr-3 mb-0 d-none d-md-block" style="width: 80px;">采购单号:</label>
                                                <input class="form-control" v-model="search_form.purchasement_number" placeholder="请输入采购单号"/>
                                            </div>
                                        </div>

                                        <div class="col-md-3 my-2 my-md-0">
                                            <div class="d-flex align-items-center">
                                                <label class="mr-3 mb-0 d-none d-md-block" style="width: 80px;">订单单号:</label>
                                                <input class="form-control" v-model="search_form.order_number" placeholder="请输入订单单号"/>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row align-items-center mt-5">
                                        <div class="col-md-3 my-2 my-md-0">
                                            <div class="d-flex align-items-center">
                                                <label class="mr-3 mb-0 d-none d-md-block" style="width: 80px;">调库单号:</label>
                                                <input class="form-control" v-model="search_form.allot_number" placeholder="请输入调库单号"/>
                                            </div>
                                        </div>

                                        <div class="col-md-3 my-2 my-md-0">
                                            <div class="d-flex align-items-center">
                                                <label class="mr-3 mb-0 d-none d-md-block" style="width: 80px;">盘库单号:</label>
                                                <input class="form-control" v-model="search_form.check_number" placeholder="请输入盘库单号"/>
                                            </div>
                                        </div>

                                        <div class="col-md-3 my-2 my-md-0">
                                            <div class="d-flex align-items-center">
                                                <label class="mr-3 mb-0 d-none d-md-block" style="width: 80px;">仓库:</label>
                                                <b-form-select class="form-control" v-model="search_form.warehouse_id" :options="warehouse_list"  name="warehouse_id">

                                                </b-form-select>
                                            </div>
                                        </div>


                                        <a href="#" class="btn btn-light-primary px-6 font-weight-bold" v-on:click="searchList()">Search</a>
                                    </div>
                                </div>

                            </div>
                        </div>
                        <!--end::Search Form-->
                        <!--end: Search Form-->


                        <KTDatatable
                                v-bind:list="list"
                                v-bind:column="column"
                                v-bind:showSelect="false"
                                v-bind:showPagination="true"
                                v-bind:pageSize="page.limit"
                                v-bind:pageCount="page.pageCount"
                                v-bind:total="page.total"
                                @operateHandler="operateHandler"
                                @pagechangeHandler="pagechangeHandler"
                        ></KTDatatable>

                    </div>
                </div>
                <!--end::Card-->
            </div>
            <!--end::Container-->
        </div>

        <v-dialog v-model="dialog_show" persistent max-width="700px">
            <component
                    :key="dialog_id"
                    :title="dialog_title"
                    :visible="dialog_show"
                    :is="dialog_view"
                    :dialog_data="dialog_data"
                    @opeareResultHandler="opeareResultHandler"></component>
        </v-dialog>

    </v-app>

</template>
<style lang="css">
    @import "~@/assets/css/common.css";
</style>
<script>
    import KTSubheader from "@/view/layout/subheader/Subheader.vue";
    import KTDatatable from "@/view/content/tables/Datatable.vue";
    import KTDatePicker from "@/view/content/date/DatePicker.vue";
    import addWareHouse from '@/view/pages/warehouse/AddWareHouse';
    import updateWareHouse from '@/view/pages/warehouse/UpdateWareHouse';

    import KTUtil from "@/assets/js/components/util";
    import {getStockLedgerList} from '@/api/stock';
    import {getWareHouse, getShelf} from '@/api/warehouse';

    export default {
        name: "ledger_view",
        components: {
            KTSubheader,
            KTDatatable,
            KTDatePicker
        },
        created: function(){
            var _this = this;
            _this.searchList();
            getWareHouse().then(function(res) {
                if(res && res.response){
                    _this.warehouse_list = KTUtil.listToSelectOptions(res.response, 'id', 'name');
                }
            });
        },
        data() {
            return {
                nav: {
                    subheaderDisplay: true,
                    breadcrumbs : [{'id':'', 'route':'/stock', 'title':'库存管理'}, {'id':'', 'route':'/stock/showleger', 'title':'库存流水'}],
                    pageTitle: '仓库'
                },
                list: [],
                warehouse_list: [],
                column: [{
                    field: 'create_time',
                    title: '时间',
                    width: 150,
                    formatter: function(row) {
                        return KTUtil.timestamp2date(row.create_time);
                    }
                },{
                    field: 'product_id',
                    title: '产品信息',
                    width: 150,
                    formatter: function(row) {
                        var obj = JSON.parse(row.attr_info);
                        var html = '<div class="ml-2">';
                        html += '<div class="line-height">'+ row.product_title +'</div>';
                        html += '<div class="line-height">'+ row.sku +'</div>';
                        html += '<div class="line-height">';
                        for(var key in obj){
                            html += '<span class="label label-primary label-inline font-weight-lighter mr-2" title="'+ key +'">'+ obj[key] +'</span>';
                        }
                        html += '</div>';
                        html += '</div>';
                        return html;
                    }
                },{
                    field: 'price',
                    title: '价格',
                },{
                    field: 'summary',
                    title: '类型',
                    formatter: function(row) {
                        if(row.summary == 1){
                            return '手动入库 <span class="label label-success label-inline"> + ' + row.quantity + '</span>'
                        } else if(row.summary == 2){
                            return '手动出库 <span class="label label-danger label-inline"> - ' + row.quantity + '</span>'
                        } else if(row.summary == 3){
                            return '自动入库 <span class="label label-success label-inline"> + ' + row.quantity + '</span>'
                        } else if(row.summary == 4){
                            return '自动出库 <span class="label label-danger label-inline"> - ' + row.quantity + '</span>'
                        } else if(row.summary == 5){
                            return '盘库入库 <span class="label label-success label-inline"> + ' + row.quantity + '</span>'
                        } else if(row.summary == 6){
                            return '盘库出库 <span class="label label-danger label-inline"> - ' + row.quantity + '</span>'
                        }  else if(row.summary == 7){
                            return '调拨入库 <span class="label label-success label-inline"> + ' + row.quantity + '</span>'
                        }  else if(row.summary == 8){
                            return '调拨出库 <span class="label label-danger label-inline"> - ' + row.quantity + '</span>'
                        }
                    }
                },{
                    field: 'warehouse_name',
                    title: '仓库名称',
                },{
                    field: 'shelf_number',
                    title: '货架',
                }],
                search_form: {
                    begin_time: '',
                    end_time: '',
                    purchasement_number: '',
                    order_number: '',
                    allot_number: '',
                    check_number: '',
                    product_id: this.$route.query.product_id,
                    warehouse_id: this.$route.query.warehouse_id
                },
                page: {
                    currPage: 1,
                    limit: 15,
                    pageCount:0,
                    total:0,
                },
                dialog_id: 0,
                dialog_show: false,
                dialog_title: '',
                dialog_view: null,
                dialog_data: null
            }
        },
        watch: {

        },
        methods: {
            searchList() {
                var _this = this;
                var data = _this.search_form;
                data.page = _this.page.currPage;
                data.limit = _this.page.limit;
                getStockLedgerList(data).then(function(res){
                    if(res){
                        _this.list = res.response.list;
                        _this.page.total = res.response.total;
                        _this.page.pageCount = res.response.count;
                    }
                });
            },
            operateHandler(clazz, row) {
                var _this = this;
                if(clazz.indexOf('la-edit') > -1){
                    //编辑操作
                    this.dialog_id = new Date().getTime();
                    this.dialog_show = true;
                    this.dialog_title = '编辑仓库';
                    this.dialog_view = updateWareHouse;
                    this.dialog_data = {
                        form_data: row
                    };
                } else if(clazz.indexOf('la-trash-o') > -1){

                    //删除操作
                    KTUtil.confirm('操作提示', '是否确认删除该条记录？', function(){
                        deleteWareHouse({id: row.id}).then(function (res) {
                            if (res) {
                                KTUtil.toast(_this, '结果提醒', '删除成功', 'success');
                                _this.searchList();
                            }
                        });
                    });
                }
            },
            pagechangeHandler(page, pageSize) {
                this.page.currPage = page;
                this.page.limit = pageSize;
                this.searchList();
            },
            addWareHouse() {
                this.dialog_id = new Date().getTime();
                this.dialog_show = true;
                this.dialog_title = '新增仓库';
                this.dialog_view = addWareHouse;
                this.dialog_data = {

                };
            },
            opeareResultHandler(result) {
                if(result){
                    this.searchList();
                }
                this.dialog_show = false;
            }
        }
    }
</script>
